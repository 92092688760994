import React from 'react';
import Layout from 'components/Layout';
import { StaticQuery, graphql } from 'gatsby';
import {
  Container,
  FormOrder,
  Heading,
  FormOrderConfirmation,
} from 'components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import CartContext from 'context/CartContext';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

function CustomerDetailsPage({ data }) {
  const { checkout } = React.useContext(CartContext);
  const richText = JSON.parse(data.contentfulPages?.body.raw);

  console.log('checkout', checkout);

  return (
    <Layout pageTitle="Place your order">
      <Container>
        <div className="w-full max-w-lg m-auto">
          {checkout && (
            <>
              <Heading className="text-center">
                {`${data.contentfulPages?.title}.`}
              </Heading>
              <div
                className="rich-text text-center"
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(richText),
                }}
              />
              <FormOrder />
            </>
          )}
          {!checkout && <FormOrderConfirmation />}
        </div>
      </Container>
    </Layout>
  );
}

export default CustomerDetailsPage;

export const query = graphql`
  query customerDetailsPageQuery {
    contentfulPages(slug: { eq: "contact" }) {
      title
      slug
      body {
        raw
      }
    }
  }
`;
